import React, { useEffect, useState, useRef } from 'react';
import { func, bool, shape, string } from 'prop-types';
import Autosuggest from 'react-autosuggest';
import Highlighter from 'react-highlight-words';
import dynamicModuleRegistery from 'dynamic-module-registry';
import { Grid, FormHelperText } from 'propulsion-rc-v2';
import validatorStringify from '../../../ui-validator-stringify/src/ui-validator-stringify';
import SmartyStreetsService from './smarty-streets-service';
import { PLTextField } from '../';
import { useLCContext } from '@shared_modules/local-propulsion/components/PageProvider';
import { trackHeapEvent } from '@shared_modules/custom-heap-tracking-attributes';
import useDidMountEffect from '@shared_modules/local-propulsion/hooks/useDidMountEffect';
import classNames from 'classnames';
import { ALPHA_CHARACTERS_REGEX, SPECIAL_LIMITED_CHARACTERS_REGEX } from '@shared_modules/baui-constants';

import './styles.scss';

let autoCompleteConfig = dynamicModuleRegistery.get('autoCompleteConfig');
const borrowerStates = dynamicModuleRegistery.get('borrowerStates');

// TODO: Unit Testing
// TODO: We need to remove isSecondary dependency.
/**
 * AutocompleteAddress Component to display Address information in fields.
 *
 * @component
 * @example
 * return (
 *   <AutocompleteAddress
 *        address={address}
 *        clearValidationErrors={clearValidationErrors}
 *        handleBlur={handleBlur}
 *        handleFocus={handleFocus}
 *        handleInputChange={handleInputChange}
 *        isSecondary={isSecondary}
 *        showHelperText={showHelperText}
 *        labels={{ street: 'Street Address' }}
 *        secondaryUsePrimaryAddress={secondaryUsePrimaryAddress}
 *        updateAddress={updateAddress}
 *        validationErrors={validationErrors}
 *        warningMessages={warningMessages}
 *      />
 *      <Component />
 *   </AutocompleteAddress>
 * );
 *
 * @param   {object}          props                   props Object
 * @param   {object}          props.address  object with all address fields
 * @param   {string=}         props.address.streetAddress  streetAddress value
 * @param   {string=}         props.address.state  state value
 * @param   {string=}         props.address.city  city value
 * @param   {string=}         props.address.zip  zip value
 * @param   {object=}         props.validationErrors  object with all validations for address fields
 * @param   {object=}         props.warningMessages   object with all warning messages for address fields
 * @param   {Function=}  props.clearValidationErrors  function to be call when we select a suggestion form the list
 * @param   {Function=} props.updateAddress to handle event when suggestion is selected with newAddress
 * @param   {React.FormEventHandler=} props.handleBlur
 * @param   {React.FormEventHandler=} props.handleInputChange onChange input method handler
 * @param   {React.FormEventHandler=} props.handleFocus
 * @param   {Boolean=} props.isSecondary value to handle if is coborrower's flow
 * @param   {Boolean=} props.secondaryUsePrimaryAddress value to handle if is secondary borrower is using the same address
 * @param   {Boolean=} props.autoFocus needs to be focus or not
 * @param   {object=} props.customAutoSuggestProps object with custom auto suggest props to bind with field
 * @param   {object=} props.showHelperText object with any helper validation to be displayed
 * @param   {object=} props.inputNames override input names
 * @param   {object=} props.labels override label values
 * @param   {string=} props.className custom className
 *
 * @return  {JSX.Element}        React component
 */
const AutocompleteAddress = ({
  clearValidationErrors,
  updateAddress,
  validationErrors,
  warningMessages,
  handleBlur,
  handleInputChange,
  handleFocus,
  address: { streetAddress, state, city, zip },
  isSecondary,
  secondaryUsePrimaryAddress,
  autoFocus,
  customAutoSuggestProps,
  showHelperText,
  inputNames,
  labels,
  className,
}) => {
  let smartyStreetsService;
  const lcContext = useLCContext();
  if (!autoCompleteConfig && lcContext.autoCompleteConfig) autoCompleteConfig = lcContext.autoCompleteConfig;
  if (!smartyStreetsService)
    smartyStreetsService = new SmartyStreetsService({
      authId: autoCompleteConfig.authId,
      suggestions: 5,
      supportedStates: (borrowerStates || []).filter(state => state.eligible).map(state => state.stateCode),
      suggestionURL: autoCompleteConfig.suggestionURL,
      detailURL: autoCompleteConfig.detailURL,
    });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [suggestions, setSuggestions] = useState([]);
  const [currentAddress, setCurrentAddress] = useState('');
  const [selectedSuggestion, setSelectedSuggestion] = useState({});

  const streetRef = useRef(null);
  const zipRef = useRef(null);

  const heapEvent = message =>
    trackHeapEvent('React Address AutoComplete', null, {
      ACTION: 'Suggestion Selected',
      MESSAGE: message,
    });
  const isAllFieldsEmpty = !(streetAddress || city || state || zip);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 767);
    if (isAllFieldsEmpty) {
      updateAddress({
        streetAddress: '',
        city: '',
        state: '',
        zip: '',
      });
    }
  }, []);

  useDidMountEffect(() => {
    if (!/^[0-9].*$/.test(currentAddress)) return; // if input not start with number
    const fetchSuggestion = async () => {
      const {
        data: { suggestions: foundSuggestions },
      } = await smartyStreetsService.getSuggestions(currentAddress);
      if (foundSuggestions) {
        const suggestions = foundSuggestions.map(suggestion => ({
          streetAddress: suggestion.street_line,
          city: suggestion.city,
          state: suggestion.state,
          hint: `${suggestion.street_line}, ${suggestion.city}, ${suggestion.state}`,
        }));
        setSuggestions(suggestions);
      }
    };

    fetchSuggestion();
  }, [currentAddress]);

  useDidMountEffect(() => {
    const fetchDetails = async () => {
      const {
        data: [foundAddress],
      } = await smartyStreetsService.getDetail(selectedSuggestion.hint);
      if (foundAddress) {
        updateAddress({
          streetAddress: foundAddress.delivery_line_1,
          city: foundAddress.components.city_name,
          state: foundAddress.components.state_abbreviation,
          zip: foundAddress.components.zipcode,
        });

        heapEvent('prefilled with zipcode');
      } else {
        updateAddress({
          streetAddress: selectedSuggestion.streetAddress,
          city: selectedSuggestion.city,
          state: selectedSuggestion.state,
          zip: '',
        });
        heapEvent('prefilled without zipcode');
        if (zipRef && zipRef.current) zipRef.current.focus();
      }
      // Clear the validationErrors if any incase there are new suggestions
      clearValidationErrors();
    };

    fetchDetails();
  }, [selectedSuggestion]);

  const onSuggestionsFetchRequested = ({ value }) => setCurrentAddress(value);
  const onSuggestionsClearRequested = () => setSuggestions([]);
  const onSuggestionSelected = (event, { suggestion }) => setSelectedSuggestion(suggestion);
  const getSuggestionValue = suggestion => suggestion.streetAddress;
  const renderSuggestion = (suggestion, { query }) => (
    <Highlighter
      highlightClassName="react-autosuggest__suggestion--strong"
      searchWords={query.split(/\s/).filter(word => word)}
      autoEscape
      textToHighlight={suggestion.hint}
    />
  );

  const inputLabels = {
    street: labels.street || 'Work Address',
    city: labels.city || 'City',
    state: labels.state || 'State',
    zip: labels.zip || 'Zip',
  };

  const streetAddressKey = inputNames.streetAddress || 'streetAddress';
  const isDisabled = isSecondary && secondaryUsePrimaryAddress;
  const helperText = {
    state: 'Enter a two-letter state (eg: CA, TX)',
  };
  // TODO: we need to include all warningMessages inside of this component or keep all outside.
  const hasError = !!validationErrors[streetAddressKey] && Object.keys(validationErrors[streetAddressKey]).length > 0;
  const hasWarning = !!(
    !hasError &&
    warningMessages[streetAddressKey] &&
    Object.keys(warningMessages[streetAddressKey]).length > 0
  ); // only supporting streetAddress warning for now

  // Autosuggest will pass through all these props to the input.
  const autoSuggestProps = {
    name: streetAddressKey,
    autoComplete: isMobile ? 'address-line1' : 'street-address',
    autoFocus,
    disabled: isDisabled,
    value: streetAddress || '',
    onBlur: handleBlur,
    onChange: handleInputChange,
    onFocus: handleFocus,
    ...customAutoSuggestProps,
    inputProps: {
      maxLength: 50,
      autoComplete: 'off',
      'data-qa-id': streetAddressKey,
      'data-nid-target': streetAddressKey,
      ...(customAutoSuggestProps.inputProps || {}),
    },
  };

  const customHelperText = () => {
    if (!suggestions.length) {
      const helperProps = {
        'data-qa-id': 'street-address-helper-text',
        focused: true,
        required: true,
      };

      let message = '';
      if (hasError) {
        helperProps.error = !!validationErrors[streetAddressKey];
        message = validatorStringify(validationErrors[streetAddressKey]);
        return <FormHelperText {...helperProps}>{message}</FormHelperText>;
      }

      if (showHelperText[streetAddressKey]) {
        message = hasWarning
          ? warningMessages[streetAddressKey]
          : 'Enter a residential address (no P.O. boxes or commercial addresses)';
        return <FormHelperText {...helperProps}>{message}</FormHelperText>;
      }
    }
  };

  const handleOnChange = e => {
    e.target.value = e.target.value.replace(/\D/g, '');
    handleInputChange(e);
  };

  const specialChars = e => {
    if (SPECIAL_LIMITED_CHARACTERS_REGEX.test(e.key)) {
      e.preventDefault();
    }
  };

  const alphaChars = e => {
    if (!ALPHA_CHARACTERS_REGEX.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleOnStateChange = e => {
    e.target.value = e.target.value.toUpperCase();
    handleInputChange(e);
  };

  return (
    <Grid container className={classNames('AutoComplete', className)}>
      <Grid item className="AutoComplete-row InputContainer-autosuggestContainer" xs={12}>
        <Autosuggest
          ref={streetRef}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={autoSuggestProps}
          renderInputComponent={({ ref, ...inputProps }) => (
            <PLTextField
              inputRef={ref}
              intent={hasError ? 'error' : hasWarning ? 'warning' : null}
              label={inputLabels.street}
              {...inputProps}
            />
          )}
        />
        {customHelperText()}
      </Grid>
      {!isAllFieldsEmpty && (
        <Grid item data-container="address-group" xs={12}>
          <Grid container>
            <Grid item xs={12} className="AutoComplete-row">
              <PLTextField
                name={inputNames.city}
                type="text"
                inputProps={{
                  maxLength: 40,
                  value: city,
                }}
                onBlur={handleBlur}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onKeyPress={e => {
                  specialChars(e);
                }}
                label={inputLabels.city}
                errors={validationErrors.city}
                disabled={isDisabled}
                autoComplete="address-level2"
                // TODO: get this value from outside due to not all the cases need to focus the input
                // autoFocus={!!(streetAddress && !city)}
                data-analytics={inputNames.city}
              />
            </Grid>
            <Grid item xs={12} className="AutoComplete-row">
              <Grid container justifyContent="space-between" spacing={4}>
                <Grid item xs={6}>
                  <PLTextField
                    name={inputNames.state}
                    type="text"
                    inputProps={{
                      maxLength: 2,
                      value: state,
                    }}
                    onBlur={handleBlur}
                    onChange={handleOnStateChange}
                    onFocus={handleFocus}
                    onKeyPress={e => {
                      specialChars(e);
                      alphaChars(e);
                    }}
                    label={inputLabels.state}
                    helperText={showHelperText.state ? helperText.state : null}
                    errors={validationErrors.state}
                    disabled={isDisabled}
                    autoComplete="address-level1"
                    autoFocus={!!(streetAddress && city && !state)}
                    data-analytics={inputNames.state}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PLTextField
                    name={inputNames.zip}
                    inputRef={zipRef}
                    type="tel"
                    inputProps={{
                      maxLength: 5,
                      value: zip,
                    }}
                    onBlur={handleBlur}
                    onChange={handleOnChange}
                    onFocus={handleFocus}
                    label={inputLabels.zip}
                    errors={validationErrors.zip}
                    disabled={isDisabled}
                    autoComplete="postal-code"
                    data-analytics={inputNames.zip}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

AutocompleteAddress.propTypes = {
  address: shape({}).isRequired,
  handleInputChange: func.isRequired,
  secondaryUsePrimaryAddress: bool,
  validationErrors: shape({}).isRequired,
  warningMessages: shape({}),
  updateAddress: func.isRequired,
  isSecondary: bool,
  handleBlur: func.isRequired,
  handleFocus: func.isRequired,
  clearValidationErrors: func,
  autoFocus: bool,
  labels: shape({}),
  customAutoSuggestProps: shape({}),
  showHelperText: shape({}),
  inputNames: shape({
    city: string.isRequired,
    zip: string.isRequired,
    state: string.isRequired,
    streetAddress: string.isRequired,
  }),
  className: string,
};

AutocompleteAddress.defaultProps = {
  clearValidationErrors: () => {},
  secondaryUsePrimaryAddress: false,
  isSecondary: false,
  autoFocus: false,
  labels: {},
  customAutoSuggestProps: {},
  showHelperText: {},
  inputNames: {
    zip: 'zip',
    city: 'city',
    state: 'state',
    streetAddress: 'streetAddress',
  },
  warningMessages: {},
};

export default AutocompleteAddress;

