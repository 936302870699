import React from 'react';
import { object } from 'prop-types';
import Section from '../Section';
import MemberReview from '../MemberReview';
import ReviewWidget from '../ReviewWidget';
import { useDmContext } from '../../use-dm-context-provider';

const MemberEndorsement = () => {
  const { dmContent } = useDmContext();
  const memberReviewContent = dmContent.layoutMemberReviewSection;
  return (
    <Section classes="Layout-sectionPadding">
      <div className="Layout-title u-textCenter u-title1Text u-colorDenim500 u-textAlignCenter">
        {memberReviewContent.title}
      </div>
      <div className="Layout-memberReviewSection">
        <MemberReview memberReviewContent={memberReviewContent} />
        <ReviewWidget />
      </div>
      <div className="Layout-smilingLady">{memberReviewContent.smilingLady}</div>
    </Section>
  );
};

export default MemberEndorsement;

