import React from 'react';

import lcIcon from '../images/lc-icon-only.svg';

import smilingLady from '../images/smiling-lady.png';
import starRating from '../images/starRating.svg';

import watchIcon from '../images/watch-icon.svg';
import offerIcon from '../images/offer-icon.svg';
import cashIcon from '../images/cash-icon.svg';

import creditIconA from '../images/A/icon_credit.svg';
import creditIconB from '../images/B/icon_credit.svg';

import loanIconA from '../images/A/icon_loan.svg';
import loanIconB from '../images/B/icon_loan.svg';

import houseIconA from '../images/A/icon_house.svg';
import houseIconB from '../images/B/icon_house.svg';

const repeatsAboutUsPoints = {
  title: undefined,
  1: {
    darkIcon: creditIconA,
    icon: creditIconB,
    title: 'Pay down high-interest debt',
    text: (
      <span>
        Get up to a 5% APR discount when you use your loan to pay down credit card or other debt.<sup>2</sup>
      </span>
    ),
    classes: 'HelpCalloutItem--offsetBottom',
  },
  2: {
    darkIcon: loanIconA,
    icon: loanIconB,
    title: 'Make a major purchase',
    text: 'Cover whatever matters most to you with a personal loan up to $40,000.',
    classes: 'HelpCalloutItem--offsetBottom',
  },
  3: {
    darkIcon: houseIconA,
    icon: houseIconB,
    title: 'Tackle your next project',
    text: 'Don’t wait to start your next home improvement project with fast funding.',
  },
};

const CtaDisclaimer = () => (
  <span>
    Checking your rate won’t affect your credit score.
    <sup className="EasyLoanCallout-superscriptText">1</sup>
  </span>
);

const ctaText = 'Check Your Rate';

const repeatsTopSection = {
  headline: {
    repeats: 'Lock in a competitive fixed rate today and start paying down debt fast',
    prospect: 'Pay down high-interest debt and save',
  },
  label: {
    repeats: 'Enter your RSVP code to check your rate:',
    prospect: 'Enter your RSVP code',
  },
  body: {
    repeats:
      'Join over 5 million members who have used LendingClub to pay down debt, refinance credit cards, build savings, and reach their financial goals.',
    prospect: 'Take the next step and enter your unique code below.',
  },
  subhead: {
    repeats: <CtaDisclaimer />,
    prospect: <CtaDisclaimer />,
  },
  ctaText: {
    repeats: ctaText,
    prospect: ctaText,
  },
};

const repeatsEasyloanCallout = {
  title: 'Getting your loan is easy',
  1: {
    icon: watchIcon,
    iconAltText: 'Watch Icon',
    title: 'Check your rate in seconds',
    text: 'Enter your RSVP code above to quickly check your rate.',
  },
  2: {
    icon: offerIcon,
    iconAltText: 'Offer Icon',
    title: 'Apply in minutes',
    text: 'Finish your customized, pre-filled application.',
  },
  3: {
    icon: cashIcon,
    iconAltText: 'Cash Icon',
    title: 'Get funded in 24 hours',
    text: (
      <span>
        Receive your money in as little as one day upon approval.
        <sup className="EasyLoanCallout-superscriptText">3</sup>
      </span>
    ),
  },
};

const repeatsContent = {
  topSection: {
    headline: repeatsTopSection.headline.repeats,
    body: repeatsTopSection.body.repeats,
    ctaText: repeatsTopSection.ctaText.repeats,
    subhead: repeatsTopSection.subhead.repeats,
    textfieldLabel: repeatsTopSection.label.repeats,
    isCoverImgVariant: false,
  },
  aboutSection: {
    title: 'Reach your financial goals with LendingClub',
    icon: <img src={lcIcon} className="AboutLC-icon" alt="LC logo icon" />,
    body: 'Since 2007, we’ve helped more than 5 million members get over $90 billion in personal loans so they can save money, pay down debt, and reach their other financial goals.',
  },
  helpCallout: {
    ...repeatsAboutUsPoints,
  },
  easyLoanCallout: {
    ...repeatsEasyloanCallout,
  },
  layoutMemberReviewSection: {
    title: 'What our members say',
    rating: <img className="MemberReview-img" src={starRating} alt="starRating" aria-hidden />,
    quote:
      'I like LendingClub because I’ve used them in the past. They have my information on file. I seem to get the best rates and best terms. It’s a quick loan. Straight to my account. Done in a few days.',
    name: (
      <span>
        - Tamra B<sup>4</sup>
      </span>
    ),
    smilingLady: <img src={smilingLady} width={335} height={347} alt="smilingLady" />,
  },
};

export const directMailContent = {
  prospect: {
    topSection: {
      headline: repeatsTopSection.headline.prospect,
      body: repeatsTopSection.body.prospect,
      ctaText: repeatsTopSection.ctaText.prospect,
      subhead: repeatsTopSection.subhead.prospect,
      textfieldLabel: repeatsTopSection.label.prospect,
      isCoverImgVariant: true,
    },
    aboutSection: {
      title: 'Reach your financial goals with LendingClub',
      icon: <img src={lcIcon} className="AboutLC-icon" alt="icon" />,
      body: 'Since 2007, we’ve helped more than 5 million members get over $90 billion in personal loans so they can save money, pay down debt, and reach their other financial goals.',
    },
    helpCallout: {
      ...repeatsAboutUsPoints,
    },
    easyLoanCallout: {
      ...repeatsEasyloanCallout,
    },
    layoutMemberReviewSection: {
      ...repeatsContent.layoutMemberReviewSection,
    },
  },
  repeats: {
    ...repeatsContent,
  },
};

export const DM_PAGE_KEY = 'dm_page';

export const dmTypes = {
  prospect: 'prospect',
  repeats: 'repeats',
  ita: 'ita',
  lead: 'lead', // oldflow myinstantoffer
};

export const flowTypes = {
  DM: 'DM',
  MEMBER_INVITE_DM: 'MEMBER_INVITE_DM',
};

