/* istanbul ignore file */
import { constants } from '../../../../shared_modules/baui-utils/client';

const {
  messages: { error: ERROR },
} = constants;

// this file contains all the constants related to processing the funding code
// a user puts into the form.

export const DM_CODE_MAX_LENGTH = 12;
export const MAX_INVALID_DM_CODE_ATTEMPTS = 3;

// URL that we submit the funding code to, this is handled by the
// server in /personal-dm
export const DM_CODE_SUBMIT_URL = '/apply/myinstantoffer/submit';

export const DM_MI_CODE_SUBMIT_URL = '/apply/member-invite/submitMICode';

// this is the page we redirect to if the user fails submission 3 times in a row.
export const DM_CODE_LEADS_REDIRECT_URL = '/pd/dm/personal-loan/leads';

export const LOGIN_REDIRECT_URL = '/auth/login';

export const PI1_URL = '/apply/personal/identity';

// In a more complex page were we are handling both the server and client aspect of
// we would want to split out stuff like error codes and api urls into the their own constants files
// so we could share them between the two services in order to have consistency between the them.
// As such, for DM we are still using the older sever from /personal-dm so we dont need to maintain
// these things, but we need to make sure we have some kind of consistency between what the server is sending
// us and how we respond and record events.

// This approach is just one of many way of dealing with how to abstract and maintain things like error messaging
// and as such should be re-evaluated if attempting to try something in a more complex page. For example, this
// approach is fragile if we have to have different error messages depending on the experiments being run.

// ----- error codes -----
export const DM_CODE_UNEXPECTED = 'DM_CODE_UNEXPECTED';
export const DM_CODE_LENGTH = 'DM_CODE_LENGTH';
export const DM_CODE_ALPHANUMERIC_ERROR = 'DMCODE_ALPHANUMERIC_ERROR';

// this error code is coming from /personal-dm so this spelling matches what
// its sending us
export const DM_CODE_CLOSED = 'DMCODE_CLOSED';
export const DM_CODE_EXPIRED = 'DMCODE_EXPIRED';
export const DM_CODE_INACTIVE = 'DMCODE_INACTIVE';
export const DM_CODE_INVALID = 'DMCODE_INVALID';
export const DM_CODE_NOT_FOUND = 'DMCODE_NOT_FOUND';

// ----- Error messaging ------
export const DM_ERROR_UNEXPECTED = {
  error: DM_CODE_UNEXPECTED,
  message: 'Something unexpected has happened. Please try again later.',
};

export const DM_ERROR_CODE_LENGTH = {
  error: DM_CODE_LENGTH,
  message: 'Please enter your personalized alphanumeric code',
};

export const DM_CODE_NOT_ALPHANUMERIC = {
  error: DM_CODE_ALPHANUMERIC_ERROR,
  message: ERROR.DMCODE_ALPHANUMERIC_ERROR,
};

export const errorTypes = {
  [DM_CODE_INVALID]: 'invalid code',
  [DM_CODE_NOT_FOUND]: 'code not found',
  [DM_CODE_EXPIRED]: 'expired code',
  [DM_CODE_CLOSED]: 'closed code',
  UNEXPECTED_ERROR: 'unexpected error',
};

export const submitResults = {
  SUCCESS: 'success',
  FAIL: 'fail',
};

export const actionTypes = {
  LOAD: 'load',
  CLOSED: 'closed',
  CLICK_CTA_START_NEW_APP: 'click_cta_start_new_app',
  CLICK_CTA_TRY_NEW_CODE: 'click_cta_try_new_code',
  CLICK_CTA_LOGIN: 'click_cta_login',
};

