import React, { useState, useEffect } from 'react';
import { useDmService } from './use-dm-service';
import { useDmContext } from './use-dm-context-provider';
import { isAlphaNumeric } from '../../../shared_modules/baui-utils/client/validation/validators';
import {
  DM_CODE_CLOSED,
  DM_CODE_EXPIRED,
  DM_CODE_INVALID,
  DM_CODE_MAX_LENGTH,
  DM_CODE_NOT_ALPHANUMERIC,
  DM_CODE_NOT_FOUND,
  DM_ERROR_CODE_LENGTH,
  DM_ERROR_UNEXPECTED,
  MAX_INVALID_DM_CODE_ATTEMPTS,
  errorTypes,
  submitResults,
} from './constants/dmCode';

import Header from './components/Header';
import Footer from './components/Footer';
import Layout from './layouts/Layout';

import Logo from './images/lc-logo.svg';

import '../direct-mail.scss';

const getIsErrorModalShown = errorCode => [DM_CODE_CLOSED, DM_CODE_EXPIRED].includes(errorCode);

const App = () => {
  const [submitStatus, setSubmitStatus] = useState(null);
  const [invalidCodeCount, setInvalidCodeCount] = useState(0);

  const { flow } = useDmContext();
  const { submitToPI1, postFundingCode, trackSubmitAttemptEvent } = useDmService();

  const attemptErrors = [DM_CODE_INVALID, DM_CODE_NOT_FOUND];

  useEffect(() => {
    if (invalidCodeCount >= MAX_INVALID_DM_CODE_ATTEMPTS) {
      trackSubmitAttemptEvent({
        errMessage: 'too many attempts',
        flow,
        submitResult: submitResults.FAIL,
        errorType: errorTypes[DM_CODE_INVALID],
        errorModalShown: true,
      });
    }
  }, [invalidCodeCount]);

  const handleApiCallErrors = data => {
    const isUserLoggedIn = data?.isUserLoggedIn;
    if (Array.isArray(data.err) && data.err.length > 0) {
      data.err.forEach(err => {
        if (attemptErrors.includes(err.error)) setInvalidCodeCount(invalidCodeCount + 1);
      });

      const [firstError] = data.err;
      setSubmitStatus({ error: firstError, isUserLoggedIn });
    } else {
      setSubmitStatus({
        error: DM_ERROR_UNEXPECTED,
        isUserLoggedIn,
      });
    }
  };

  const redirectTo = nextPage => {
    window.location.assign(nextPage);
  };

  const handleDMCodeSubmit = async fundingCode => {
    if (fundingCode.length !== DM_CODE_MAX_LENGTH) {
      trackSubmitAttemptEvent({
        errMessage: 'invalid code length',
        submitResult: submitResults.FAIL,
        errorType: errorTypes[DM_CODE_INVALID],
        errorModalShown: false,
      });
      setSubmitStatus({
        error: DM_ERROR_CODE_LENGTH,
      });

      return;
    }

    if (!isAlphaNumeric(fundingCode)) {
      trackSubmitAttemptEvent({
        errMessage: 'funding code is not alpha numeric',
        submitResult: submitResults.FAIL,
        errorType: errorTypes[DM_CODE_INVALID],
        errorModalShown: false,
      });
      setSubmitStatus({ error: DM_CODE_NOT_ALPHANUMERIC });
      setInvalidCodeCount(invalidCodeCount + 1);

      return;
    }

    const data = await postFundingCode(fundingCode);

    if (data.err) {
      const errorCode = data.err?.[0]?.error;

      trackSubmitAttemptEvent({
        fundingCode,
        flow,
        submitResult: submitResults.FAIL,
        errorType: errorTypes[errorCode] ?? errorTypes.UNEXPECTED_ERROR,
        errorModalShown: getIsErrorModalShown(errorCode),
      });

      handleApiCallErrors(data);

      return;
    }

    trackSubmitAttemptEvent({ fundingCode, flow, submitResult: submitResults.SUCCESS, errorModalShown: false });

    const { nextPage } = data;
    if (!nextPage) return;

    if (!nextPage.startsWith('/apply/personal/identity')) {
      redirectTo(nextPage);

      return;
    }

    submitToPI1(data);
  };

  return (
    <div className="DirectMail u-positionRelative">
      <Header logoImgSrc={Logo} logoAltText="LendingClub logo" classes="u-positionAbsolute" />
      <Layout onDMCodeSubmitHandler={handleDMCodeSubmit} submitStatus={submitStatus} />
      <Footer />
    </div>
  );
};

export default App;

