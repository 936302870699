export const FLOW_TYPES = {
  ILOC: 'iloc',
  LBB: 'lbb',
  TOPUP: 'topup',
  TOPUPMB: 'topup_MB',
  TOPUPCP: 'topup_CP',
  TOPUPPLUS: 'topUpPlus',
  DEFAULT: 'default',
  TOPUP_MB_PLUS: 'topup_mb_plus',
  TOPUP_CP_PLUS: 'topup_cp_plus',
};

export const PRODUCT_TYPES = {
  TOPUP: 'TOPUP',
  CLEANSWEEP: 'CLEANSWEEP',
  LBB_BCQ: 'LBB_BCQ',
};

export const SUB_PRODUCT = {
  TOPUP: 'TOPUP',
};

