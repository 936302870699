/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import { App as PropulsionApp } from 'propulsion-rc-v2';

import App from './client/App';
import { DmContextProvider } from './client/use-dm-context-provider';
import './direct-mail.scss';

import { PageProvider } from '../../shared_modules/local-propulsion/components';

ReactDOM.render(
  <PageProvider appProvider={PropulsionApp} value="direct-mail">
    <DmContextProvider>
      <App />
    </DmContextProvider>
  </PageProvider>,
  document.getElementById('direct-mail')
);

