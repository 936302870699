import React from 'react';
import { bool, func, node, string } from 'prop-types';
import { Button } from '@shared_modules/local-propulsion/components';
import { Grid } from 'propulsion-rc-v2';
import Modal from '@shared_modules/rc-modal-v2/Modal';
import { useDmService } from '../../use-dm-service';
import { DM_CODE_CLOSED, actionTypes, errorTypes } from '../../constants/dmCode';

import './index.scss';

const ROOT_CLASS = 'FundingCodeInvalidModal';

const FundingCodeInvalidModal = ({
  children,
  callToSecAction,
  errorCode,
  handleClose,
  handleCTAClick,
  primaryCTAText,
  secondaryCTAText,
  showModal,
}) => {
  const { trackErrorModal } = useDmService();
  const isClosedErrorCode = errorCode === DM_CODE_CLOSED && primaryCTAText.toLowerCase() === 'login';

  const errorType = errorTypes[errorCode];

  return (
    <Modal
      aria-labelledby={`${ROOT_CLASS}-funding-error-modal`}
      showModal={showModal}
      showCloseIcon
      handleClose={e => {
        trackErrorModal({ action: actionTypes.CLOSED, errorType });
        handleClose(e);
      }}
      ariaHideApp={false}
    >
      <Grid container className={`${ROOT_CLASS}-body`}>
        {children}
        <Grid item className={`${ROOT_CLASS}-actions`}>
          <Button
            variant="primary"
            color="red"
            tabIndex="0"
            name="button-primary-cta"
            className={`${ROOT_CLASS}-primary-cta`}
            onClick={e => {
              trackErrorModal({
                action: isClosedErrorCode ? actionTypes.CLICK_CTA_LOGIN : actionTypes.CLICK_CTA_START_NEW_APP,
                errorType,
              });
              handleCTAClick(e);
            }}
          >
            {primaryCTAText}
          </Button>
          <div className={`${ROOT_CLASS}-subTextOr`}>—OR—</div>
          <Button
            variant="tertiary"
            tabIndex="0"
            name="button-secondary-cta"
            className={`${ROOT_CLASS}-secondary-cta`}
            onClick={e => {
              trackErrorModal({
                action: isClosedErrorCode ? actionTypes.CLICK_CTA_START_NEW_APP : actionTypes.CLICK_CTA_TRY_NEW_CODE,
                errorType,
              });
              callToSecAction(e);
            }}
          >
            {secondaryCTAText}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

FundingCodeInvalidModal.propTypes = {
  children: node,
  callToSecAction: func.isRequired,
  errorCode: string,
  handleClose: func.isRequired,
  handleCTAClick: func.isRequired,
  primaryCTAText: string,
  secondaryCTAText: string,
  showModal: bool,
};

FundingCodeInvalidModal.defaultProps = {
  primaryCTAText: 'Start a New Application',
  showModal: false,
  secondaryCTAText: 'Try a Different RSVP Code',
};

export default FundingCodeInvalidModal;

