import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import MemberStatistics from '../MemberStatistics';
import Nerdwallet from '../Nerdwallet';
import TrustPilotWidget from '../TrustPilotWidget';

import './index.scss';

const ReviewWidget = ({ classes }) => {
  return (
    <div className={classnames('ReviewWidget', classes)}>
      <MemberStatistics />
      <div className="divider" />
      <Nerdwallet />
      <div className="divider" />
      <TrustPilotWidget />
    </div>
  );
};

ReviewWidget.propTypes = {
  classes: PropTypes.string,
};

export default ReviewWidget;

