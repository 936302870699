import { makeStylesPropulsion as makeStyles } from '@ui-propulsion/react-components';

const useStyles = makeStyles(
  theme => {
    const {
      typography: { pxToRem },
    } = theme;

    return {
      root: {
        width: pxToRem(28),
        cursor: 'pointer',
        position: 'absolute',
      },
    };
  },
  { name: 'BackButton' }
);

export { useStyles };

