import React from 'react';
import { string, node, arrayOf, oneOfType } from 'prop-types';
import classNames from 'classnames';
import './CheckList.scss';

const CheckListItem = ({ children, icon }) => {
  const rootClass = classNames('CheckList-item', { default: !icon });
  return (
    <li className={rootClass}>
      {icon && <img alt="" aria-hidden className="CheckList-item-icon" src={icon} />}
      <span>{children}</span>
    </li>
  );
};

CheckListItem.propTypes = {
  children: string.isRequired,
  icon: node,
};

const CheckList = ({ items, icon, className }) => {
  if (!items.length) return null;

  return (
    <ul className={classNames('CheckList', className)}>
      {items.map((bulletText, i) => (
        <CheckListItem key={i} icon={icon}>
          {bulletText}
        </CheckListItem>
      ))}
    </ul>
  );
};

CheckList.Item = CheckListItem;

CheckList.propTypes = {
  items: oneOfType([arrayOf(string), arrayOf(node)]),
  icon: node,
  className: string,
};

CheckList.defaultProps = {
  items: [],
};

export default CheckList;

