import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { func, shape, string } from 'prop-types';
import { MaskedInput } from 'propulsion-rc-v2';
import spinner from '@shared_modules/rc-offer-spinner/spinner.svg';
import { Button } from '@shared_modules/local-propulsion/components';
import WhereToFindCodeTooltip from '../WhereToFindCodeTooltip';
import dynamicModuleRegistry from 'dynamic-module-registry';
import ResponsiveImage from './ResponsiveImage';
import FundingCodeInvalidModal from '../FundingCodeInvalidModal';
import {
  DM_CODE_EXPIRED,
  DM_CODE_INACTIVE,
  DM_CODE_NOT_FOUND,
  DM_CODE_INVALID,
  DM_CODE_LEADS_REDIRECT_URL,
  DM_CODE_CLOSED,
  LOGIN_REDIRECT_URL,
  MAX_INVALID_DM_CODE_ATTEMPTS,
  PI1_URL,
  actionTypes,
  errorTypes,
} from '../../constants/dmCode';
import ModalTextContent from '../FundingCodeInvalidModal/ModalTextContent';
import { useDmService } from '../../use-dm-service';
import { useDmContext } from '../../use-dm-context-provider';

import './index.scss';

const DMCodeInput = ({ onDMCodeSubmitHandler, submitStatus }) => {
  const { dmContent } = useDmContext();
  const { isCoverImgVariant, subhead, ctaText, isCtaVariant, textfieldLabel, isLabelVariant, body, headline } =
    dmContent.topSection;
  const isDMPageRedirectVariant = false; // TODO: Need to clean up this logic line 35-48; For now we can keep this; https://lendingclub.atlassian.net/browse/UFE-2262
  const fundingCode = dynamicModuleRegistry.get('directMailFundingCodeBypass')
    ? new URL(window.location.href).searchParams.get('funding_code') ?? ''
    : '';
  const [DMCode, setDMCode] = useState({ code: fundingCode, codeFormatted: '' });
  const [showSpinner, setShowSpinner] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [invalidErrorCount, setInvalidErrorCount] = useState(1);
  const { trackErrorModal } = useDmService();

  useEffect(() => {
    let delaySubmitTimeout;
    if (isDMPageRedirectVariant && !window.history.state?.disableAutoSubmit && fundingCode) {
      // show loader
      setShowSpinner(true);
      delaySubmitTimeout = setTimeout(() => {
        window.history.replaceState({ disableAutoSubmit: true }, null);
        onDMCodeSubmitHandler(DMCode.code);
      }, 2000);
    }
    return () => {
      clearTimeout(delaySubmitTimeout);
    };
  }, []);

  const onDMCodeSubmit = event => {
    event.preventDefault();
    onDMCodeSubmitHandler(DMCode.code);
  };

  const onDMCodeChange = event => {
    // we want the raw value, not the masked value
    setDMCode({ code: event.target.rawValue, codeFormatted: event.target.value });
  };

  const hasError = submitStatus && submitStatus.error;

  const queryParams = window.location.search;

  const loginUrl = `${LOGIN_REDIRECT_URL}?login_url=${PI1_URL}${queryParams}`;

  const redirectToNewApplication = () => window.location.assign(`${DM_CODE_LEADS_REDIRECT_URL}${queryParams}`);

  const redirectToLogin = () => window.location.assign(loginUrl);

  const handleClose = () => setShowErrorModal(false);

  const isUserLoggedIn = !!(submitStatus && submitStatus.isUserLoggedIn === true);

  const commonModalTexts = {
    title: 'Invalid RSVP code',
    secondarySubtitle: 'Would you like to start a new application?',
    text: 'We would ask you a few questions to make the best offer for you in no time.',
  };

  const classNameText = 'FundingCodeInvalidModal';
  const subtitleCodeText = `RSVP code [${DMCode.codeFormatted}]`;

  const closedStatusModalContent = {
    true: {
      root: {},
      texts: {},
    },
    false: {
      root: {
        primaryCTAText: 'Login',
        secondaryCTAText: 'Start a New Application',
        callToAction: redirectToLogin,
        callToSecAction: redirectToNewApplication,
      },
      texts: {
        secondarySubtitle: 'Did you use this RSVP code in a previous application?',
        text: 'You can login to return to your application or see your loan.',
      },
    },
  };

  const handleErrorModal = errorType => {
    trackErrorModal({ action: actionTypes.LOAD, errorType: errorTypes[errorType] });
    setShowErrorModal(true);
  };

  const showModalBasedOnError = errorType => {
    if (invalidErrorCount < MAX_INVALID_DM_CODE_ATTEMPTS) {
      setInvalidErrorCount(invalidErrorCount + 1);
    } else {
      handleErrorModal(errorType);
      setInvalidErrorCount(1);
    }
  };

  const errorHandlerByType = {
    [DM_CODE_CLOSED]: {
      handler: () => handleErrorModal(DM_CODE_CLOSED),
      errorCode: DM_CODE_CLOSED,
      ...closedStatusModalContent[isUserLoggedIn]?.root,
      children: (
        <ModalTextContent
          classNameTexts={classNameText}
          texts={{
            ...commonModalTexts,
            subtitle: `The ${subtitleCodeText} you entered has already been used towards a loan.`,
            ...closedStatusModalContent[isUserLoggedIn]?.texts,
          }}
        />
      ),
    },
    [DM_CODE_INVALID]: {
      handler: () => showModalBasedOnError(DM_CODE_INVALID),
      errorCode: DM_CODE_INVALID,
      children: (
        <ModalTextContent
          classNameTexts={classNameText}
          texts={{
            ...commonModalTexts,
            subtitle: `The ${subtitleCodeText} you entered is invalid.`,
          }}
        />
      ),
    },
    [DM_CODE_NOT_FOUND]: {
      handler: () => showModalBasedOnError(DM_CODE_NOT_FOUND),
      errorCode: DM_CODE_NOT_FOUND,
      children: (
        <ModalTextContent
          classNameTexts={classNameText}
          texts={{
            ...commonModalTexts,
            subtitle: `The ${subtitleCodeText} you entered is invalid.`,
          }}
        />
      ),
    },
    [DM_CODE_EXPIRED]: {
      handler: () => handleErrorModal(DM_CODE_EXPIRED),
      errorCode: DM_CODE_EXPIRED,
      children: (
        <ModalTextContent
          classNameTexts={classNameText}
          texts={{
            title: 'Uh Oh.',
            subtitle: `Your ${subtitleCodeText} has expired`,
            secondarySubtitle: 'No worries! You can still start a new application.',
            text: 'We will ask you a few questions to make the best offer for you in no time.',
          }}
        />
      ),
    },
    [DM_CODE_INACTIVE]: {
      handler: () => handleErrorModal(DM_CODE_INACTIVE),
      callToAction: redirectToNewApplication,
      errorCode: DM_CODE_INACTIVE,
      callToSecAction: handleClose,
      children: (
        <ModalTextContent
          classNameTexts={classNameText}
          texts={{
            ...commonModalTexts,
            subtitle: `The ${subtitleCodeText} is no longer valid.`,
          }}
        />
      ),
    },
  };

  useEffect(() => {
    const submitError = hasError && submitStatus.error.error;
    errorHandlerByType[submitError]?.handler();
    return () => {
      setInvalidErrorCount(1);
    };
  }, [submitStatus]);

  useEffect(() => {
    if (hasError) {
      setShowSpinner(false);
    }
  }, [hasError]);

  const errorMsg = hasError && (
    <div className="DMCodeInput-error u-colorCardinal500 u-microText">{submitStatus.error.message}</div>
  );
  const {
    children,
    callToAction = redirectToNewApplication,
    callToSecAction = handleClose,
    errorCode,
    primaryCTAText,
    secondaryCTAText,
  } = errorHandlerByType[submitStatus?.error?.error] || {};
  return (
    <section className="DMCodeInput u-positionRelative">
      {showSpinner && (
        <div className="DMCodeInput-spinner">
          <img src={spinner} alt="We are processing your code..." />
        </div>
      )}
      <ResponsiveImage displayVariant={isCoverImgVariant} />

      <div className={classnames('DMCodeInput-container', { 'DMCodeInput-offset': !hasError })}>
        <div className="DMCodeInput-content">
          <h1 className="DMCodeInput-title u-heroText">{headline}</h1>
          <div className="DMCodeInput-desc u-bodyText">{body}</div>
          <form className="DMCodeInput-form" onSubmit={onDMCodeSubmit}>
            <label
              htmlFor="DMCode"
              className={classnames('DMCodeInput-formTitle u-title2Text', {
                'DMCodeInput-formTitle--custom': isLabelVariant,
              })}
            >
              {showSpinner ? 'Welcome! we are processing your code...' : textfieldLabel}
            </label>
            <div className="DMCodeInput-formInput">
              <MaskedInput
                maskOptions={{
                  mask: '####-####-####',
                  definitions: {
                    // define valid char as an uppercase char or num
                    '#': /[A-Z0-9]/,
                  },
                  prepare: str => str.toUpperCase(),
                  commit: (valueFormat, { unmaskedValue }) =>
                    setDMCode({ code: unmaskedValue, codeFormatted: valueFormat }),
                }}
                name="DMCode"
                defaultValue={DMCode.code}
                placeholder="Enter your 12-digit code"
                fullWidth
                onChange={onDMCodeChange}
                className="DMCodeInput-formInput"
                intent={hasError ? 'error' : undefined}
                data-qa-id="dm-code-input"
                aria-label="Enter DMCode"
                disabled={!!fundingCode && !hasError}
                id="DMCode"
                autoFocus
              />
              {errorMsg}
            </div>
            <WhereToFindCodeTooltip>
              <a className="DMCodeInput-ClickTextBelowInput u-textDecorationUnderline u-positionRelative u-fontTextMedium u-colorSapphire500">
                Where do I find my code?
              </a>
            </WhereToFindCodeTooltip>
            <div className="DMCodeInput-formBtn">
              <Button
                color="red"
                name="submit-button"
                type="submit"
                fullWidth
                className={classnames('DMCodeInput-cta', {
                  'DMCodeInput-cta--custom': isCtaVariant,
                })}
                disabled={showSpinner}
              >
                {ctaText}
              </Button>
            </div>
            <div className="u-microText u-fontTextBold u-colorDenim500">{subhead}</div>
          </form>
        </div>
      </div>
      <FundingCodeInvalidModal
        callToSecAction={() => callToSecAction()}
        handleClose={handleClose}
        handleCTAClick={() => callToAction()}
        errorCode={errorCode}
        primaryCTAText={primaryCTAText}
        showModal={showErrorModal}
        secondaryCTAText={secondaryCTAText}
      >
        {children}
      </FundingCodeInvalidModal>
    </section>
  );
};

DMCodeInput.propTypes = {
  onDMCodeSubmitHandler: func.isRequired,
  submitStatus: shape({
    error: shape({
      message: string,
    }),
  }),
};

export default DMCodeInput;

