export const prescreenStatus = {
  SESSION_MISMATCH: 'SESSION_MISMATCH',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
  INACTIVE: 'INACTIVE',
  OPEN: 'OPEN',
  MALFORMED: 'MALFORMED',
  PRODUCT_NOT_SUPPORTED: 'PRODUCT_NOT_SUPPORTED',
};

export const prescreenType = {
  ILOC: 'CLEANSWEEP',
  LBB: 'LBB',
  PL2BT: 'PL2BT',
  PL_PRE_APPROVAL: 'PL_PRE_APPROVAL',
  TOP_UP: 'TOP_UP',
};

export const PRODUCT_NOT_SUPPORTED = {
  CLEANSWEEP: 'CLEANSWEEP',
};

export const PSS_PAYLOAD_CATEGORIES = {
  MARKETING_OFFER: 'marketing_offer',
};

export const pssErrors = {
  PSSTYPE_MISMATCH: 'PSSTYPE_MISMATCH',
  PSSID_MISMATCH: 'PSSID_MISMATCH',
  USER_INELIGIBLE: 'USER_INELIGIBLE',
  CLOSED: 'TOKEN_USED',
  EXPIRED: 'TOKEN_EXPIRED',
  MALFORMED: 'MALFORMED',
  TOKEN_NOT_FOUND: 'TOKEN_NOT_FOUND',
  SESSION_MISMATCH: 'SESSION_MISMATCH',
  PRODUCT_NOT_SUPPORTED: 'PRODUCT_NOT_SUPPORTED',
};

export const preApprovedErrorStatuses = {
  ...prescreenStatus,
  TOKEN_NOT_FOUND: 'TOKEN_NOT_FOUND',
  APP_IN_PROG_RETURN: 'APP_IN_PROG_RETURN',
  APP_IN_PROG_OTHER: 'APP_IN_PROG_OTHER',
};

export const errorRedirectMap = {
  CLEANSWEEP_IDENTITY: 'CLEANSWEEP',
  TOPUP_IDENTITY: 'TOPUP',
};

export const TOPUP_PA = {
  PL_PRE_APPROVAL_TOPUP: 'PL_PRE_APPROVAL_TOPUP',
  PL_PRE_APPROVAL_CP_TOPUP: 'PL_PRE_APPROVAL_CP_TOPUP',
  PL_PRE_APPROVAL_MB_TOPUP: 'PL_PRE_APPROVAL_MB_TOPUP',
};

export const TOPUP_PLUS_PA = {
  // TODO: we will enable these after topup plus is fully launched.
  // PL_PRE_APPROVAL_CP_TOPUP_PLUS: 'PL_PRE_APPROVAL_CP_TOPUP_PLUS',
  // PL_PRE_APPROVAL_MB_TOPUP_PLUS: 'PL_PRE_APPROVAL_MB_TOPUP_PLUS',
};

export const TOPUP_PLUS_PL2PL = {
  PL2PL_MB_TOPUP_PLUS: 'PL2PL_MB_TOPUP_PLUS',
  PL2PL_CP_TOPUP_PLUS: 'PL2PL_CP_TOPUP_PLUS',
};

export const TOPUP_PL2PL = {
  PL2PL_TOPUP: 'PL2PL_TOPUP',
  PL2PL_MB_TOPUP: 'PL2PL_MB_TOPUP',
  PL2PL_CP_TOPUP: 'PL2PL_CP_TOPUP',
};

export const TOPUP_PLUS_SOURCES = {
  ...TOPUP_PLUS_PA,
  ...TOPUP_PLUS_PL2PL,
};

export const TOPUP_PA_SOURCES = {
  ...TOPUP_PA,
  ...TOPUP_PLUS_PA,
};

export const TOPUP_SOURCES = {
  ...TOPUP_PL2PL,
  ...TOPUP_PLUS_SOURCES,
  ...TOPUP_PA_SOURCES,
};

export const fsStateToPrescreenTypeMap = {
  CLEANSWEEP: ['CLEANSWEEP'],
  CLEANSWEEP_IDENTITY: ['CLEANSWEEP'],
  TOPUP: Object.keys(TOPUP_SOURCES),
  TOPUP_IDENTITY: Object.keys(TOPUP_SOURCES),
};

export const SOURCE_TYPE_MAP = {
  PL2PL_TOPUP: prescreenType.TOP_UP,
  PL2PL_CP_TOPUP: prescreenType.TOP_UP,
  PL2PL_MB_TOPUP: prescreenType.TOP_UP,
  PL2PL_MB_TOPUP_PLUS: prescreenType.TOP_UP,
  PL2PL_CP_TOPUP_PLUS: prescreenType.TOP_UP,
  PL_PRE_APPROVAL_TOPUP: prescreenType.TOP_UP,
  PL_PRE_APPROVAL_MB_TOPUP: prescreenType.TOP_UP,
  PL_PRE_APPROVAL_CP_TOPUP: prescreenType.TOP_UP,
  PL_PRE_APPROVAL_MB_TOPUP_PLUS: prescreenType.TOP_UP,
  PL_PRE_APPROVAL_CP_TOPUP_PLUS: prescreenType.TOP_UP,
};

export const isMatchingPrescreenType = (currentStateFromUrl, pssType) => {
  return (fsStateToPrescreenTypeMap[currentStateFromUrl] || []).includes(pssType);
};

const PL_MKTG_KEY = 'PL2PL_TOPUP_MKTG_OFFER';
const PA_MKTG_KEY = 'PL_PA_TOPUP_MKTG_OFFER';

const payloadsStrategies = {
  PL2PL_TOPUP: PL_MKTG_KEY,
  PL2PL_CP_TOPUP: PL_MKTG_KEY,
  PL2PL_MB_TOPUP: PL_MKTG_KEY,
  PL2PL_MB_TOPUP_PLUS: PL_MKTG_KEY,
  PL2PL_CP_TOPUP_PLUS: PL_MKTG_KEY,
  PL_PRE_APPROVAL_TOPUP: PA_MKTG_KEY,
  PL_PRE_APPROVAL_MB_TOPUP: PA_MKTG_KEY,
  PL_PRE_APPROVAL_CP_TOPUP: PA_MKTG_KEY,
  PL_PRE_APPROVAL_CP_TOPUP_PLUS: PA_MKTG_KEY,
  PL_PRE_APPROVAL_MB_TOPUP_PLUS: PA_MKTG_KEY,
};

export const topUpPlusDiscountUpTo = '0.50%';

export const getPSSContext = prescreenContext => {
  switch (prescreenContext.tokenType) {
    case prescreenType.TOP_UP:
      const currentPayload =
        getValueByPartialKey(prescreenContext.payloads, payloadsStrategies[prescreenContext.type]) || {};
      const {
        minTopUpAmount,
        maxTopUpAmount,
        loanId,
        estimatedPayOffAmount15Days,
        asOfDate,
        outstandingPrincipal,
        monthlyPaymentAmount,
        interestRate,
        apr,
        term,
      } = currentPayload.payload || {};

      const existingLoanDetails = {
        loanId,
        estimatedPayOffAmount15Days,
        asOfDate,
        outstandingPrincipal,
        monthlyPaymentAmount,
        interestRate,
        apr,
        term,
      };

      return {
        minCashAmount: minTopUpAmount,
        maxCashAmount: maxTopUpAmount,
        existingLoanDetails,
      };
    default:
      return {};
  }
};

const pssMaxMin = ['minCashAmount', 'maxCashAmount'];

const pssFields = [
  pssMaxMin[0],
  pssMaxMin[1],
  'loanId',
  'estimatedPayOffAmount15Days',
  'asOfDate',
  'outstandingPrincipal',
  'monthlyPaymentAmount',
  'interestRate',
  'apr',
  'term',
];

const mustHavePSSElements = {
  PL2PL_TOPUP: pssMaxMin,
  PL2PL_CP_TOPUP: pssMaxMin,
  PL2PL_MB_TOPUP: pssMaxMin,
  PL_PRE_APPROVAL_TOPUP: pssMaxMin,
  PL_PRE_APPROVAL_MB_TOPUP: pssMaxMin,
  PL_PRE_APPROVAL_CP_TOPUP: pssMaxMin,
  // TODO: we will enable these back to "pssFields" after topUpPlus has the data ready from DS team.
  PL2PL_MB_TOPUP_PLUS: pssMaxMin,
  PL2PL_CP_TOPUP_PLUS: pssMaxMin,
  PL_PRE_APPROVAL_MB_TOPUP_PLUS: pssMaxMin,
  PL_PRE_APPROVAL_CP_TOPUP_PLUS: pssMaxMin,
};

export const isMalformedPSSEntry = (prescreenContext, type) => {
  const requiredKeys = mustHavePSSElements[type];
  if (!requiredKeys) return false;

  const typeSpecificContext = getPSSContext(prescreenContext);

  const { minCashAmount, maxCashAmount, existingLoanDetails = {} } = typeSpecificContext;

  const pssContext = {
    minCashAmount,
    maxCashAmount,
    ...existingLoanDetails,
  };

  return !areKeysMissing(requiredKeys, pssContext);
};

const areKeysMissing = (requiredKeys, inputObject) =>
  requiredKeys.every(key => inputObject.hasOwnProperty(key) && inputObject[key] !== undefined);

/**
 * Retrieves the value from a JSON object by searching for a key that includes the specified partial key.
 *
 * @param {Object} obj - The JSON object to search within.
 * @param {string} partialKey - The partial key to search for within the keys of the JSON object.
 * @returns {*} The value associated with the found key, or undefined if no key includes the partial key.
 */
export const getValueByPartialKey = (obj, partialKey) => {
  const key = Object.keys(obj).find(k => k.includes(partialKey));
  return key ? obj[key] : undefined;
};

