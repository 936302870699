import React from 'react';
import nerdwallet from './images/nerdwallet.svg';

import './index.scss';

const Nerdwallet = () => (
  <div className="nerdwallet-statistics">
    <img alt="dollar sign" src={nerdwallet} width={60} height={60} />
  </div>
);

export default Nerdwallet;

