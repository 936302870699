import React from 'react';
import { element, oneOfType, string } from 'prop-types';

import './index.scss';

const EasyLoanCalloutItem = ({ icon, iconAltText, title, text }) => (
  <div className="EasyLoanCalloutItem">
    <div className="EasyLoanCalloutItem-image">
      <img src={icon} alt={iconAltText} />
    </div>
    <div className="EasyLoanCalloutItem-title u-colorDenim500 u-title2Text">{title}</div>
    <div className="EasyLoanCalloutItem-text u-colorDenim500 u-bodyText">{text}</div>
  </div>
);

EasyLoanCalloutItem.propTypes = {
  icon: string.isRequired,
  iconAltText: string.isRequired,
  title: oneOfType([string, element]).isRequired,
  text: oneOfType([string, element]).isRequired,
};

export default EasyLoanCalloutItem;

