import React from 'react';
import classnames from 'classnames';
import { useMediaQuery, MEDIA_QUERY } from '@shared_modules/hooks';
import { getResponsiveImage } from '../../utils';

import coverImgSm from './images/cover-626.jpg';
import coverImgMd from './images/cover-768.jpg';
import coverImgLg from './images/cover-1024.jpg';
import coverImgXl from './images/cover-1280.jpg';
import coverImgXXl from './images/cover-1440.jpg';

import coverImgSmV2 from './images/cover-626-prospect.jpg';
import coverImgMdV2 from './images/cover-768-prospect.jpg';
import coverImgLgV2 from './images/cover-1024-prospect.jpg';
import coverImgXlV2 from './images/cover-1280-prospect.jpg';
import coverImgXXlV2 from './images/cover-1440-prospect.jpg';

export const DM_IMAGES_MAP = {
  repeats: {
    SM: coverImgSm,
    MD: coverImgMd,
    LG: coverImgLg,
    XL: coverImgXl,
    XXL: coverImgXXl,
  },
  prospect: {
    SM: coverImgSmV2,
    MD: coverImgMdV2,
    LG: coverImgLgV2,
    XL: coverImgXlV2,
    XXL: coverImgXXlV2,
  },
};

/**
 * ResponsiveImage - Display Cover Image based on Screen Size
 * @param {Object} props
 * @param {Boolean=} props.displayVariant to render the default or variant cover image
 * @returns {JSX.Element} React Element
 */
const ResponsiveImage = ({ displayVariant }) => {
  const isPhoneLg =
    useMediaQuery(MEDIA_QUERY.PHONE_MD_AND_UP) ||
    useMediaQuery(MEDIA_QUERY.PHONE_SM_AND_UP) ||
    useMediaQuery(MEDIA_QUERY.PHONE_LG_AND_UP);
  const isTabletSm = useMediaQuery(MEDIA_QUERY.TABLET_SM_AND_UP);
  const isTabletLg = useMediaQuery(MEDIA_QUERY.TABLET_LG_AND_UP);
  const isTabletMd = useMediaQuery(MEDIA_QUERY.TABLET_MD_AND_UP);
  const isDesktop = useMediaQuery(MEDIA_QUERY.DESKTOP_SM_AND_UP);
  const isDesktopMd = useMediaQuery(MEDIA_QUERY.DESKTOP_MD_AND_UP);

  const expImages = DM_IMAGES_MAP[displayVariant ? 'prospect' : 'repeats'];

  let image = getResponsiveImage({ isPhoneLg, isTabletSm, isTabletMd, isTabletLg, isDesktop, isDesktopMd }, expImages);

  return (
    <img
      src={image.src}
      className={classnames(`DMCodeInput-img-${image.suffix}`, {
        'u-positionAbsolute': !['sm'].includes(image.suffix),
      })}
      alt={`cover-img-${image.suffix}`}
    />
  );
};

ResponsiveImage.defaultProps = {
  displayVariant: false,
};

export default ResponsiveImage;

