import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import HelpCalloutItem from '../HelpCalloutItem';
import { useDmContext } from '../../use-dm-context-provider';

import './index.scss';

const HelpCallout = ({ theme }) => {
  const { dmContent } = useDmContext();
  const helpCalloutContent = dmContent.helpCallout;
  const isDark = theme === 'dark';
  return (
    <div
      className={classNames(
        'HelpCallout',
        { 'u-bgColorDenim500': isDark },
        { 'HelpCallout--light u-bgColorWhite': !isDark }
      )}
    >
      <div
        className={classNames(
          'HelpCallout-header u-microText u-fontTextMedium',
          { 'u-fontTextBold': !isDark },
          { 'u-colorWhite': isDark },
          { 'u-colorDenim500': !isDark }
        )}
      >
        {helpCalloutContent?.title}
      </div>

      <HelpCalloutItem
        title={helpCalloutContent[1].title}
        text={helpCalloutContent[1].text}
        classes="HelpCalloutItem--offsetBottom"
        icon={isDark ? helpCalloutContent[1].darkIcon : helpCalloutContent[1].icon}
        titleClassNames={isDark ? 'u-colorSky500' : 'u-colorDenim500'}
        textClassNames={isDark ? 'u-colorWhite' : 'u-colorDenim500'}
      />
      <HelpCalloutItem
        title={helpCalloutContent[2].title}
        text={helpCalloutContent[2].text}
        icon={isDark ? helpCalloutContent[2].darkIcon : helpCalloutContent[2].icon}
        classes="HelpCalloutItem--offsetBottom"
        titleClassNames={isDark ? 'u-colorSky500' : 'u-colorDenim500'}
        textClassNames={isDark ? 'u-colorWhite' : 'u-colorDenim500'}
      />
      <HelpCalloutItem
        title={helpCalloutContent[3].title}
        text={helpCalloutContent[3].text}
        icon={isDark ? helpCalloutContent[3].darkIcon : helpCalloutContent[3].icon}
        titleClassNames={isDark ? 'u-colorSky500' : 'u-colorDenim500'}
        textClassNames={isDark ? 'u-colorWhite' : 'u-colorDenim500'}
      />
    </div>
  );
};

HelpCallout.propTypes = {
  theme: string,
};

HelpCallout.defaultProps = {
  theme: 'dark',
};

export default HelpCallout;

