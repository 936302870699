import React from 'react';
import { object, string } from 'prop-types';
import classnames from 'classnames';
import { Button } from '@shared_modules/local-propulsion/components';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import { MEMBERS_REVIEW_URL } from '../../constants/userResourceUrls';

import './index.scss';

const MemberReview = ({ classes, memberReviewContent }) => {
  const handleReadMoreReviews = e => {
    heapTrackEvent('PL DM - click read more reviews', 'cta');
    window.open(MEMBERS_REVIEW_URL, '_blank');
  };

  return (
    <div className={classnames('MemberReview', classes)}>
      <div className="MemberReview-imgWrapper">{memberReviewContent.rating}</div>
      <div className="MemberReview-quoteSection">
        <div className="MemberReview-quote u-colorDenim500">{memberReviewContent.quote}</div>
        <div className="MemberReview-memberName">{memberReviewContent.name}</div>
        <div className="MemberReview-btn">
          <Button
            fullWidth
            color="red"
            variant="secondary"
            name="MemberReview-btn"
            rel="noopener noreferrer"
            onClick={handleReadMoreReviews}
          >
            Read More Reviews
          </Button>
        </div>
      </div>
    </div>
  );
};

MemberReview.propTypes = {
  classes: string,
  memberReviewContent: object.isRequired,
};

MemberReview.defaultProps = {
  classes: '',
};

export default MemberReview;

