import { directMailContent } from '../constants/content';

export const getDMPageContent = variant => {
  return directMailContent[variant];
};

/**
 * returns the appropriate image to display based on the screen size.
 * @param {Object} params
 * @param {Boolean=} params.isPhoneLg flag to identify small screens (mobile)
 * @param {Boolean=} params.isTabletSm flag to identify tablet small and up
 * @param {Boolean=} params.isTabletMd flag to identify tablet medium and up
 * @param {Boolean=} params.isTabletLg flag to identify tablet large and up
 * @param {Boolean=} params.isDesktop  flag to identify desktop and up
 * @param {Boolean=} params.isDesktopMd flag to identify more than 1220 and up
 * @param {Object} images mapped images object
 * @param {String} images.SM
 * @param {String} images.MD
 * @param {String} images.LG
 * @param {String} images.XL
 * @param {String} images.XXL
 * @returns {Object} cover image obj
 */
export const getResponsiveImage = (
  { isPhoneLg, isTabletSm, isTabletMd, isTabletLg, isDesktop, isDesktopMd },
  images = { SM: '', MD: '', LG: '', XL: '', XXL: '' }
) => {
  let image = {};

  switch (true) {
    case isDesktopMd:
      image.src = images.XXL;
      image.suffix = 'xxl';
      break;
    case isDesktop:
      image.src = images.XL;
      image.suffix = 'xl';
      break;
    case isTabletLg:
      image.src = images.LG;
      image.suffix = 'lg';
      break;
    case isTabletMd:
      image.src = images.MD;
      image.suffix = 'md';
      break;
    case isTabletSm || isPhoneLg:
      image.src = images.SM;
      image.suffix = 'sm';
      break;
    default:
  }
  return image;
};

