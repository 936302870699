import React from 'react';
import HelpCallout from '../HelpCallout';
import { useDmContext } from '../../use-dm-context-provider';

import './index.scss';

const AboutLC = () => {
  const { dmContent } = useDmContext();
  const { body, icon, title } = dmContent.aboutSection;
  const lcHistory = (
    <div className="AboutLC-history">
      <span>{icon}</span>
      <div className="AboutLC-title u-title1Text u-colorWhite">{title}</div>
      <div className="AboutLC-text u-bodyText u-colorWhite">{body}</div>
    </div>
  );

  return (
    <div className="AboutLC u-bgColorDenim500 u-width100Percent">
      <div className="AboutLC-content">
        {lcHistory}
        <div className="AboutLC-divider" />
        <div className="AboutLC-helper">
          <HelpCallout />
        </div>
      </div>
    </div>
  );
};

export default AboutLC;

