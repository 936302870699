import axios from 'lc-http';
import { useDmContext } from './use-dm-context-provider';
import { DM_CODE_SUBMIT_URL, DM_MI_CODE_SUBMIT_URL } from './constants/dmCode';
import { flowTypes } from './constants/content';

import { heapTrackEvent } from '../../../shared_modules/baui-heap-tracking';

export const useDmService = () => {
  const { flow } = useDmContext();
  const postFundingCode = async fundingCode => {
    try {
      const isMemberInviteFlow = flow === flowTypes.MEMBER_INVITE_DM;
      const queryParams = isMemberInviteFlow ? window.location.search : '';

      const url = isMemberInviteFlow ? DM_MI_CODE_SUBMIT_URL : DM_CODE_SUBMIT_URL;

      const res = await axios.post(`${url}${queryParams}`, { fundingCode });

      return res.data;
    } catch (err) {
      return { err: err.message };
    }
  };

  const submitToPI1 = data => {
    const form = document.createElement('form');
    form.action = data.nextPage;
    form.method = 'POST';
    document.body.appendChild(form);

    const codeInput = document.createElement('input');
    codeInput.type = 'hidden';
    codeInput.name = 'fundingCode';
    codeInput.value = data.code;

    form.appendChild(codeInput);

    form.submit();
  };

  const trackSubmitAttemptEvent = options => {
    heapTrackEvent('PL_Originations_DM_Landing_Page_Submit_Attempt', options);
  };

  const trackErrorModal = options => {
    heapTrackEvent('PL_Originations_DM_Landing_Page_Funding_Code_Error_Modal', options);
  };

  return {
    submitToPI1,
    postFundingCode,
    trackSubmitAttemptEvent,
    trackErrorModal,
  };
};

