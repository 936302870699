import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import Section from '../Section';
import EasyLoanCalloutItem from '../EasyLoanCalloutItem';
import { useDmContext } from '../../use-dm-context-provider';

import './index.scss';

const EasyLoanCallout = ({ classNames, contentClassNames }) => {
  const { dmContent } = useDmContext();
  const easyLoanSteps = dmContent.easyLoanCallout;
  return (
    <Section classes={classnames('EasyLoanCallout', classNames)}>
      <div className={classnames('EasyLoanCallout-content u-bgColorWhite u-textAlignCenter', contentClassNames)}>
        <div className="EasyLoanCallout-title u-title1Text u-colorDenim500">{easyLoanSteps.title}</div>
        {Object.keys(easyLoanSteps).map(
          (key, index) =>
            index !== 3 && (
              <EasyLoanCalloutItem
                key={index}
                icon={easyLoanSteps[key].icon}
                iconAltText={easyLoanSteps[key].iconAltText}
                title={index + 1 + '. ' + easyLoanSteps[key].title}
                text={easyLoanSteps[key].text}
              />
            )
        )}
      </div>
    </Section>
  );
};

EasyLoanCallout.propTypes = {
  classNames: string,
  contentClassNames: string,
};

export default EasyLoanCallout;

