import React, { createContext, useContext } from 'react';

import { DM_PAGE_KEY, dmTypes, flowTypes } from './constants/content';
import { getDMPageContent } from './utils';

const DmContext = createContext({ dmContent: {}, type: dmTypes.lead, flow: flowTypes.DM });

const getType = flow => {
  if (flow === flowTypes.DM) return dmTypes.lead;

  const qs = new URLSearchParams(window.location.search);
  return dmTypes[qs.get(DM_PAGE_KEY)?.toLowerCase()] ?? dmTypes.repeats;
};

const getFlow = () => {
  const isMemberInviteFlow = window.location.pathname.includes('member-invite');
  return isMemberInviteFlow ? flowTypes.MEMBER_INVITE_DM : flowTypes.DM;
};

export const DmContextProvider = ({ children }) => {
  const flow = getFlow();
  const dmType = getType(flow);
  const pageType = dmType === dmTypes.prospect ? dmTypes.prospect : dmTypes.repeats;
  const dmContent = getDMPageContent(pageType);

  return <DmContext.Provider value={{ type: dmType, flow, dmContent }}>{children}</DmContext.Provider>;
};

export const useDmContext = () => useContext(DmContext);

