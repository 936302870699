import React from 'react';
import { func, string, shape, bool, number, oneOfType } from 'prop-types';
import PLTextField from '../PLTextField';

const CurrencyInput = props => {
  const {
    name,
    label,
    onBlur,
    defaultValue,
    onChange,
    onFocus,
    errors,
    warningText,
    helperText,
    disabled,
    ...otherProps
  } = props;

  // we are using the type=tel prop
  // to pop up the number pad on mobile
  return (
    <div className="CurrencyInput">
      <PLTextField
        type="tel"
        name={name}
        label={label}
        component="Currency"
        onBlur={onBlur}
        defaultValue={defaultValue}
        onChange={onChange}
        onFocus={onFocus}
        errors={errors}
        warningText={warningText}
        helperText={helperText}
        disabled={disabled}
        {...otherProps}
      />
    </div>
  );
};

CurrencyInput.propTypes = {
  name: string.isRequired,
  label: string,
  onBlur: func,
  defaultValue: oneOfType([string, number]),
  onChange: func.isRequired,
  onFocus: func,
  errors: shape({}),
  warningText: string,
  helperText: string,
  disabled: bool,
  inputProps: shape({}),
};

CurrencyInput.defaultProps = {
  defaultValue: '',
  label: '',
  onBlur: () => {},
  onFocus: () => {},
  errors: {},
  warningText: '',
  helperText: '',
  disabled: false,
};

export default CurrencyInput;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');