import React from 'react';
import { node, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const HelpCalloutItem = ({ icon, title, text, classes, titleClassNames, textClassNames }) => (
  <div className={classNames('HelpCalloutItem', classes)}>
    <div className="HelpCalloutItem-img">
      <img src={icon} className="HelpCalloutItem-icon" width={60} height={60} alt={title} aria-hidden />
    </div>
    <div className="HelpCalloutItem-content">
      <div className={classNames('HelpCalloutItem-title u-title2Text', titleClassNames)}>{title}</div>
      <div className={classNames('HelpCalloutItem-text u-bodyText', textClassNames)}>{text}</div>
    </div>
  </div>
);

HelpCalloutItem.propTypes = {
  icon: string.isRequired,
  title: oneOfType([string, node]).isRequired,
  text: oneOfType([string, node]).isRequired,
  classes: string,
  titleClassNames: string,
  textClassNames: string,
};

HelpCalloutItem.defaultProps = {
  classes: '',
  titleClassNames: '',
  textClassNames: '',
};

export default HelpCalloutItem;

